import Link from "next/link";
import Image from "next/image";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Button } from "@/components/Button";
import Script from "next/script";
import caStateCapitol from "@/images/california-state-capitol.jpg";

export function Hero() {
  return (
    <div className="bg-white lg:overflow-hidden lg:pb-8">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative max-w-md bg-fixed px-4 sm:mx-auto sm:max-w-2xl sm:px-6 sm:text-center lg:mx-0 lg:flex lg:items-center lg:px-0 lg:text-left">
            <div className="z-0">
              {/* Begin optional announcement */}
              {/* <Link
                href="#"
                className="mb-4 inline-flex items-center rounded-full border-[1px]  border-gray-300 bg-white p-1 pr-2 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80 sm:text-base lg:text-sm xl:text-base"
              >
                <span className="rounded-full bg-gradient-to-r from-brand-light to-brand-button px-3 py-0.5 text-xs font-semibold uppercase leading-5 tracking-wide text-black">
                  Announcement
                </span>
                <span className="ml-4 text-sm">Fall Legislative Slate</span>
                <ChevronRightIcon
                  className="ml-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </Link> */}
              {/* End optional announcement */}
              <h1 className="text-3xl font-bold tracking-tighter text-gray-900 drop-shadow-sm sm:text-5xl lg:text-[2.9rem] xl:text-6xl">
                Govern For&nbsp;
                <span className="bg-gradient-to-r from-brand-light to-brand-text bg-clip-text pb-3 text-transparent drop-shadow-sm sm:pb-5">
                  California
                </span>
              </h1>
              {/* <p className="mt-3 text-lg font-medium leading-normal text-gray-800 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-xl md:mt-5 lg:mx-0">
                We are the special interest for the public’s interests on the
                ballot and in the California State Legislature.
              </p> */}
              <p className="text-md mx-auto mt-5 max-w-prose text-gray-800 lg:text-lg">
                For decades, special interests have wielded outsized influence
                over California’s governance, resulting in low-quality public
                services and ever-increasing spending with little to show for
                it. That’s because the general interest has been poorly
                represented. GFC is a special interest for the general interest
                and a supporter of like-minded organizations.
              </p>

              <div className="justify-left mt-5 flex items-center sm:mt-8 sm:justify-center lg:justify-start">
                <Link href="/about" className="body-link">
                  Learn more
                </Link>
                <div id="twttr-container" className="ml-5 mt-0">
                  <a
                    href="https://twitter.com/govern4ca?ref_src=twsrc%5Etfw"
                    className="twitter-follow-button"
                    data-size="large"
                    data-lang="en"
                    data-dnt="true"
                    data-show-count="false"
                  >
                    @govern4ca
                  </a>
                  <Script
                    src="https://platform.twitter.com/widgets.js"
                    onReady={() => {
                      window.twttr.widgets.load(
                        document.getElementById("twttr-container"),
                      );
                    }}
                  ></Script>
                </div>
              </div>
            </div>
          </div>
          <div className="my-12 lg:m-0">
            <div className="max-w-md px-4 sm:mx-auto sm:max-w-2xl sm:px-6 lg:mx-0 lg:hidden">
              <Image
                priority={true}
                className="rounded-md"
                src={caStateCapitol}
                alt="California State Capitol Building"
              />
            </div>
            <Image
              priority={true}
              className="hidden h-full w-full rounded-md object-cover lg:block"
              src={caStateCapitol}
              alt="California State Capitol Building"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
