import Image from "next/image";
import Link from "next/link";
import DateFormatter from "@/components/DateFormatter";
import { getDateUrlSlug } from "@/lib/posts";

export function BlogList({ posts, prefix = "/news" }) {
  return (
    <div className="relative">
      <div className="grid max-w-md gap-8 sm:mx-auto sm:max-w-lg lg:mx-0 lg:max-w-7xl lg:grid-cols-3">
        {posts.map(({ node }) => (
          // <Link
          //   href={post.href}
          //   key={post.id}
          //   className="flex flex-col overflow-hidden rounded-lg border-[1px] shadow-lg hover:shadow-2xl"
          // >
          <div
            key={node.title}
            className="flex flex-col overflow-hidden rounded-lg border-[1px] shadow-lg hover:shadow-xl"
          >
            {/* <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.coverImage}
                  alt="Blog post image"
                />
              </div> */}
            <div className="flex flex-1 flex-col justify-between bg-white p-6 text-left">
              <div className="flex-1">
                <p className="text-sm font-medium text-brand-text">
                  {node.categories.nodes.length > 0 &&
                    node.categories.nodes.map((category, index) => (
                      <span key={index}>
                        {category.name}
                        {index < node.categories.nodes.length - 1 && (
                          <>,&nbsp;</>
                        )}
                      </span>
                    ))}
                </p>
                <div className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    <Link
                      href={`${prefix}/${getDateUrlSlug(node)}/${node.slug}`}
                      className="text-gray-700 hover:text-gray-900"
                    >
                      {node.title}
                    </Link>
                  </p>
                  <div
                    className="mt-3 text-base text-gray-500"
                    dangerouslySetInnerHTML={{ __html: node.excerpt }}
                  />
                  {/* <p className="mt-3">
                    <Link
                      href={`${prefix}/${getDateUrlSlug(node)}/${node.slug}`}
                      className="text-sm font-normal text-gray-500 hover:text-gray-700"
                    >
                      Read
                    </Link>
                  </p> */}
                </div>
              </div>
              <div className="mt-6 flex items-center">
                {/* {Object.keys(node.author.node.avatar.url).length > 0 && (
                  <div className="flex-shrink-0">
                    <Image
                      className="h-10 w-10 rounded-full object-cover"
                      src={node.author.node.avatar.url}
                      width={2400}
                      height={1600}
                      alt={`${node.author.node.name} avatar`}
                    />
                  </div>
                )} */}
                <div
                // className={`${
                //   Object.keys(node.author.node.avatar.url).length > 0
                //     ? "ml-3"
                //     : ""
                // }`}
                >
                  <p className="text-sm font-medium text-gray-900">
                    {node.author.node.name}
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <DateFormatter dateString={node.date} />
                    {/* <span aria-hidden="true"> &middot; </span> */}
                    {/* <span> {post.readingLength} read </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
